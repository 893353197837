import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="row footer">
      <p className="copyRight">
        Copyright &copy; 2023{" "}
        <span className="fHighlight">Succeed Programming</span>. All rights
        reserved.
      </p>
    </section>
  );
};

export default Footer;
