import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Meta from "../../../Assets/meta.png";
import Stanford from "../../../Assets/stanford.png";
import Mlh from "../../../Assets/mlh.svg";
import tCS from "../../../Assets/tcs.png";

const data = [
  {
    title: "Stanford Data Science Intern",
    subtitle:
      "Paired Stanford PhD canidates in a program sponsored by the Department of Biomedical Data Science and Stanford Data Science.",
    icon: Stanford,
  },
  {
    title: "Meta Production Engineering Fellow",
    subtitle:
      "Completed several Production Engineering projects under the direct guidance of Meta and MLH mentors.",
    icon: Meta,
  },
  {
    title: "Professional Tutor & Supervisor",
    subtitle:
      "Taught several students about programming through our time at The Coder School earning professional experience.",
    icon: tCS,
  },
  {
    title: "Major League Hacking Founder Track",
    subtitle:
      "Invited to a startup incubator program presented by the largest hackathon organization MLH and VC firm The Fund ($12B AUM).",
    icon: Mlh,
  },
];

const BrowserSupport = () => {
  return (
    <Box p={2} mb={2}>
      <Box marginBottom={4} textAlign={"center"}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
        >
          Our Experience
        </Typography>
        <Typography fontWeight={700} variant={"h4"}>
          We'll help you learn the skills and master the application process
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {data.map((item, i) => (
          <Grid item xs={12} md={3} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={"fade-up"}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box
                  component={Avatar}
                  width={80}
                  height={80}
                  marginBottom={2}
                  src={item.icon}
                  imgProps={{ style: { objectFit: "contain" } }}
                />
                <Typography
                  variant={"h6"}
                  gutterBottom
                  fontWeight={500}
                  align={"center"}
                >
                  {item.title}
                </Typography>
                <Typography align={"center"} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrowserSupport;
