export const PricingData = [
  [
    {
      title: "Programming Help",
      name: "",
      price: 50,
      desc: "Get help building your project, doing homework, or studying for a test.",
    },
  ],
  [
    {
      title: "Resume Review",
      name: "",
      price: 50,
      desc: "Ensure your resume is the right length, has the perfect content, and is formatted beautifully.",
    },
    {
      title: "Online Presence Review",
      name: "",
      price: 50,
      desc: "Keep you LinkedIn, Github, and Portfolio website up to standard with the right content.",
    },
    {
      title: "Program Application Review",
      name: "",
      price: 75,
      desc: "Nail the written application portion of important programs like Google STEP. I can provide some programs if you're just beginning.",
    },
  ],
  [
    {
      title: "AP Computer Science A Tutoring",
      name: "",
      price: 50,
      desc: "Get the training you need from someone who scored a 5 on the exam with time to spare.",
    },
    {
      title: "AP Computer Science Principles Tutoring",
      name: "",
      price: 50,
      desc: "Get ahead of the pack in APCSP to crush AP Computer Science A next year.",
    },
  ],
  [
    { title: "null", name: "Web Development", price: 49 },
    { title: "null", name: "Web Development", price: 69 },
    { title: "null", name: "Web Development", price: 89 },
  ],
  [
    { title: "null", name: "SEO", price: 44 },
    { title: "null", name: "SEO", price: 77 },
    { title: "null", name: "SEO", price: 88 },
  ],
  [
    {
      title: "Get Off The Ground",
      name: "",
      price: 100,
      desc: "Consulting to make sure your idea stage startup is technically feasible.",
    },
    {
      title: "FinTech Startup Consulting",
      name: "",
      price: 150,
      desc: "NON-LEGAL consulting to make sure your FinTech takes off compliantly. Referrals to services that will make building easier are also available.",
    },
    {
      title: "Contract me to build your idea",
      price: 100,
      name: "",
      desc: "$100 initial call to discuss your idea. Negotiable pricing after that.",
    },
  ],
];
