import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuffer } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const NavBar = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 350) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);

  const scrollTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });
  return (
    <Navbar
      className={`navbar navbar-expand-lg navbar-light ${
        isSticky ? "navDefault" : "navDefault"
      }`}
      expand="lg"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
          <FontAwesomeIcon icon={faBuffer} className="brnIcon" /> Succeed{" "}
          <span className="navHighlight">Programming</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mainNav" activeKey="/home">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link"
                onClick={() =>
                  window["scrollTo"]({ top: 0, behavior: "smooth" })
                }
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#services" className="nav-link">
                Services
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#contact" className="nav-link">
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                onClick={() =>
                  window
                    .open("https://calendly.com/david-pantoja", "_blank")
                    ?.focus()
                }
                to="/"
              >
                <button className="loginBtn">Book Now</button>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
