import React from "react";
import "./HappyClient.css";

const HappyClient = () => {
  return (
    <section className="ourValue">
      <div className="row container mx-auto"></div>
    </section>
  );
};

export default HappyClient;
