import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Contact.css";
import contactImg from "../../../Assets/contact.svg";
// import swal from 'sweetalert'
import Fade from "react-reveal/Fade";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();

    var templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    const emailResponse = await emailjs.send(
      "service_qfkon1v",
      "template_n4ewoe3",
      templateParams,
      "k9ktVKq4bOTX-x6Uf"
    );

    if (emailResponse.status !== 200) {
      return alert("Error, email not sent. Email david27pantoja[at]gmail.com");
    }
    setName("");
    setEmail("");
    setMessage("");
    alert("Email sent successfully!");
  };

  return (
    <section id="contact">
      <Col md={11} className="mx-auto">
        <Row>
          <Col md={6}>
            <Fade duration={2000} left>
              <form onSubmit={sendEmail} className="contactForm">
                <h4 className="miniTitle">
                  CONTACT US FOR BOOKING AND QUESTIONS
                </h4>
                <h5 className="sectionTitle">GET IN TOUCH</h5>
                <Row>
                  <Col md={12} lg={6}>
                    <input
                      placeholder="Your Name"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </Col>
                  <Col md={12} lg={6}>
                    <input
                      placeholder="Your Email"
                      type="email"
                      name="from_email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </Col>
                  <Col md={12}>
                    <textarea
                      placeholder="Your Message..."
                      name="message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      required
                    ></textarea>
                  </Col>
                </Row>
                <button className="branBtn" type="submit">
                  Submit
                </button>
              </form>
            </Fade>
          </Col>
          <Col md={6}>
            <Fade duration={2000} right>
              <img src={`${contactImg}`} alt="" className="img-fluid" />
            </Fade>
          </Col>
        </Row>
      </Col>
    </section>
  );
};

export default Contact;
