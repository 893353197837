import React from "react";
import teamPic from "../../../Assets/about.svg";
import Fade from "react-reveal/Fade";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const About = () => {
  return (
    <section className="about overflow-hidden py-5">
      <div className="row w-100">
        <div className="row col-md-11 mx-auto ">
          <div className="col-md-6 img">
            <Fade duration={2000} left>
              <img src={`${teamPic}`} alt="" className="img-fluid" />
            </Fade>
          </div>
          <div className="col-md-6 ps-2">
            <Fade duration={2000} right>
              <p className="miniTitle">about us</p>
              <h1 className="headerTitle">
                HOW WE CAN HELP YOUR{" "}
                <span className="headerHighlight">GOALS</span>{" "}
              </h1>
              <p className="headerContent">
                Our goal is to bring high school upperclassmen, university
                students, and pre-revenue startup founders to the next level. We
                offer various tech-related services, all intending to position
                you in the perfect spot to apply to that university, internship,
                or incubator. See our services below and how we assist you with
                certain program applications.
              </p>
              <Box
                component={Button}
                href="#contact"
                className="branBtn"
                variant="contained"
                color="white"
                backgroundColor={"#7456f7"}
                size="large"
                marginRight={{ sm: 2 }}
                marginTop={{ xs: 2, sm: 0 }}
              >
                learn More
              </Box>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
