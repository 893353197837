import React from "react";
import "./Service.css";
import Fade from "react-reveal/Fade";
import "./Service.css";

const Service = ({ service }) => {
  const { name, price, description, img, link } = service;

  const handleClick = () => {
    if (link.split("")[0] === "#") {
      window.scroll(0, 10000);
    } else {
      window.open(link, "_blank")?.focus();
    }
  };

  return (
    <div className="col-md-6 col-lg-4 service">
      <Fade bottom duration={2700} distance="70px">
        <div className="service-card">
          <div className="text-center">
            <img src={`${img}`} alt="" className="serviceImg" />
          </div>
          <h4 className="serviceName">{name}</h4>
          <p className="serviceDes">{description}</p>
          <div className="bookingBox">
            <p className="servicePrice">${price}</p>
            <button className="bookingBtn" href={link} onClick={handleClick}>
              Book Now
            </button>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Service;
