import React from "react";
import Service from "./Service";
import handshake from "../../../Assets/handshake.png";
import resume from "../../../Assets/resume.png";

const Services = () => {
  return (
    <section id="services" className="services">
      <h4 className="miniTitle text-center">SERVICES</h4>
      <div className="text-center">
        <h5 className="text-center sectionTitle">
          PROMOTIONAL AND PACKAGE SERVICE
        </h5>
      </div>
      <div className="row mt-4 container mx-auto justify-content-center">
        <Service
          key={1}
          service={{
            name: "Introduction",
            price: 0,
            description:
              "Book a free 15 minute call to see if this is a good match.",
            img: handshake,
            link: "https://calendly.com/david-pantoja",
          }}
        />
        <Service
          key={2}
          service={{
            name: "Resume Review",
            price: 150,
            description:
              "Offer detailed feedback on your Resume, linkedIn, Github, Portfolio, and 1 Program Application.",
            img: resume,
            link: "#contact",
          }}
        />
      </div>
    </section>
  );
};

export default Services;
